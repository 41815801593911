import React from 'react'
import Checkmark from '../../assets/svg/checkmark.svg'

interface BulletPointProps {
    text: string
}

const BulletPoint = ({ text }: BulletPointProps) => (
    <div className="bulletPointContainer">
        <Checkmark className="checkmarkIcon" />
        <span className="bulletPointText">{text}</span>
    </div>
)

export default BulletPoint
