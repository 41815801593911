import React from 'react'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ChipClasses from '../utils/chipProp'
import BulletPoint from './bulletPoint/BulletPoint'
import House from '../assets/svg/house.svg'
import Safe from '../assets/svg/safe.svg'
import Boxes from '../assets/svg/boxes.svg'
import Lotus from '../assets/svg/lotus.svg'
import Mascot from '../assets/svg/mascot.svg'
import FullLogo from '../assets/svg/fullLogo.svg'

const About = () => {
    const { t } = useTranslation('about')
    const sections = [
        { icon: <House className="sectionImage" />, section: 'house' },
        { icon: <Safe className="sectionImage safeImage" />, section: 'safe' },
        { icon: <Boxes className="sectionImage" />, section: 'boxes' },
        { icon: <Lotus className="sectionImage" />, section: 'lotus' },
    ]

    return (
        <div id="about" className="aboutPage">
            <div>
                <Chip label={<FullLogo />} {...ChipClasses} />
                <h2 className="pageTitle">{t('header')}</h2>
            </div>
            {sections.map(({ icon, section }) => (
                <div key={section} className={`${section}Section`}>
                    {icon}
                    <div className={`${section}SectionInfo`}>
                        <h3 className="sectionTitle">{t(`${section}.title`)}</h3>
                        <p className="sectionDescription">{t(`${section}.description`)}</p>
                        <BulletPoint text={t(`${section}.bullet1`)} />
                        <BulletPoint text={t(`${section}.bullet2`)} />
                        <BulletPoint text={t(`${section}.bullet3`)} />
                    </div>
                </div>
            ))}
            <div className="mascotSection">
                <Mascot className="sectionImage mascotImage" />
                <div className="mascotSectionInfo">
                    <h3 className="sectionTitle">{t(`mascot.title`)}</h3>
                    <p className="sectionDescription">{t(`mascot.description`)}</p>
                    <p className="sectionQuote">{t(`mascot.quote`)}</p>
                </div>
            </div>
        </div>
    )
}
export default About
